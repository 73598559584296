<template>
  <div>
    <v-data-table
      :items="dataGroups"
      :headers="headerGroups"
      :loading="loading"
      :footer-props="{itemsPerPageText: 'Mostrar'}"
      :search="search"
    >
      <template v-slot:item.status="{item}">
        <v-chip
          class="ma-2 font-weight-bold"
          :color="Boolean(item.status) ? 'success-alert' : 'red-alert' "
          small
          label
          pill
          dark
        >
          {{ Boolean(item.status) ? 'Activo' : 'Inactivo' }}
        </v-chip>
      </template>
      <template v-slot:item.nombre="{item}">
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-medium">
              {{ item.nombre }}
              <!-- <v-chip
                class="ma-1 px-1 font-weight-bold"
                color="info"
                outlined
                label
                x-small
              >
                +4
              </v-chip> -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item.descripcion="{item}">
        <span class="d-inline-block text-sms text-truncate">
          {{ item.descripcion }}
        </span>
      </template>
      <template v-slot:item.actions="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="editGroup(item)"
            >
              <v-icon
                size="20"
                color="blue-grey"
                v-text="'mdi-file-document-edit-outline'"
              />
            </v-btn>
          </template>
          <span>Editar Grupos</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="deleteGroup(item)"
              >
              <v-icon
                size="20"
                color="blue-grey"
                v-text="'mdi-trash-can-outline'"
              />
            </v-btn>
          </template>
          <span>Eliminar Grupo</span>
        </v-tooltip>
      </template>
      <template #loading>
        <v-skeleton-loader
          type="table-tbody"
        />
      </template>
      <template v-slot:no-data>
        <div class="text-center pa-4">
          <span class="blue-grey--text font-italic">No se encontraron Grupos Registrados</span>
        </div>
      </template>
    </v-data-table>
    <material-wait
      v-model="deleting"
      text="Elimando Grupo"
      icon="mdi-trash-can-outline"
    />
  </div>
</template>
<script>
import moment from 'moment'
import { deleteGroup } from '@/services/mensajeria'

export default {
  name: 'DataGroups',
  props:{
    search: String,
    dataGroups: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data: () =>({
    deleting: false,
    headerGroups:[
      { text: 'COD', value: 'codigo', class: 'header-data', width: '80px', align:'' },
      { text: 'Nombre', value: 'nombre', class: 'header-data', width: '17vw' },
      { text: 'Descripción', value: 'descripcion', class: 'header-data' },
      // { text: 'Remitente', value: 'remitente', class: 'header-data'},
      { text: 'Estatus', value: 'status', class: 'header-data', align: 'center', width: '180px' },
      { text: 'Acciones', value: 'actions', class: 'header-data text-center', align: 'center', width: '150px' },
    ],
  }),
  // filters: {
  //   dateSMS (date) {
  //     moment.locale('es');
  //     return date ? moment(date).format('D MMM YY, hh:mm A') : '';
  //   },
  // },
  methods: {
    editGroup(group) {
      this.$emit('edit', group)
    },

    async deleteGroup({id, nombre}) {
      const confirm = await this.$root.$confirm(
        'Eliminar Grupo',
        `¿Esta Seguro de eliminar el grupo ${nombre}?`
      )

      if(confirm){
        this.deleting = true
        try {
          const { message } = await deleteGroup({idGroup: id})
          this.$root.$showAlert(
              message,
              'success'
            );
          this.$emit('deleted', true)
        } catch (error) {
          this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
        } finally {
          this.deleting = false
        }
      }
    }
  },
}
</script>
<style>
.text-sms{
  width: 20vw !important;
  max-width: 20vw !important;
}
</style>
