var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.dataGroups,"headers":_vm.headerGroups,"loading":_vm.loading,"footer-props":{itemsPerPageText: 'Mostrar'},"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":Boolean(item.status) ? 'success-alert' : 'red-alert',"small":"","label":"","pill":"","dark":""}},[_vm._v(" "+_vm._s(Boolean(item.status) ? 'Activo' : 'Inactivo')+" ")])]}},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text font-weight-medium"},[_vm._v(" "+_vm._s(item.nombre)+" ")])],1)],1)]}},{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-sms text-truncate"},[_vm._v(" "+_vm._s(item.descripcion)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.editGroup(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"blue-grey"},domProps:{"textContent":_vm._s('mdi-file-document-edit-outline')}})],1)]}}],null,true)},[_c('span',[_vm._v("Editar Grupos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteGroup(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"blue-grey"},domProps:{"textContent":_vm._s('mdi-trash-can-outline')}})],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Grupo")])])]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center pa-4"},[_c('span',{staticClass:"blue-grey--text font-italic"},[_vm._v("No se encontraron Grupos Registrados")])])]},proxy:true}])}),_c('material-wait',{attrs:{"text":"Elimando Grupo","icon":"mdi-trash-can-outline"},model:{value:(_vm.deleting),callback:function ($$v) {_vm.deleting=$$v},expression:"deleting"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }